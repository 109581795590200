<template>
    <div>
      <page-breadcrumb title="Report" class="mb-2" />
      <div class="d-flex align-items-center">
        <form-generator :model="filter" :schema="filter_schema" />
      </div>
      <data-table-ssr id="group_list" ref="group_list" :columns="fields" :get-list-fn="getReport">
        <template #slot_recording="{slotData}">
          <a v-if="slotData.class_recording_url" :href="slotData.class_recording_url" style="text-decoration: underline;" target="_blank">Record</a>
        </template>
        <template #slot_cancelled_before_6="{slotData}">
          <span>{{isCancelledMoreThanSixHoursAgo(slotData.canceled_at, slotData.date, slotData.time)}}</span>
        </template>
      </data-table-ssr>
    </div>
  </template>
  <script>
  let STATUS_OPTIONS = [];
  let CANCELED_BY_OPTIONS = [];
  let TIME_PATTERN_OPTIONS = [];
  const teacher_email_options = [];
  const courses_options = [];
  import service from '../service'
  import { buildSelectOptions } from "@core/utils/index";
  export default {
    data() {
      return {
        filter_schema: [
          {
            cols: 3, fields: [{ field: 'start_date', label: 'Start Date', input_type: 'date' }]
          },
          {
            cols: 3, fields: [{ field: 'end_date', label: 'End Date', input_type: 'date' }]
          },
          {
            cols: 3, fields: [{ field: 'time', label: 'Slot', input_type: 'select', options: TIME_PATTERN_OPTIONS }]
          },
          {
            cols: 2, fields: [{ field: 'teacher_email_key', label: 'Teacher email' }]
          },
          {
            cols: 2, fields: [{ field: 'student_email_key', label: 'Student email' }]
          },
          {
            cols: 2, fields: [{ field: 'student_name_key', label: 'Student Name' }]
          },
          {
            cols: 3, fields: [{ field: 'status', label: 'Class Status', input_type: 'select', options: STATUS_OPTIONS }]
          },
          {
            cols: 2, fields: [{ field: 'student_market', label: 'Student Market', input_type: 'select', options: this.$store.getters['language/language_options'] }]
          },
          {
            cols: 3, fields: [{ field: 'course_id', label: 'Course', input_type: 'select', options: courses_options }]
          },
        ],
        filter: {
          start_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          end_date: new Date()
        },
        fields: [
          { label: 'Class Time', field: 'class_time', value_handler: (model) => { return `${model.date} ${model.time}` } },
          { label: 'Status', field: 'status', input_type: 'select', options: STATUS_OPTIONS},
          { label: 'Teacher Email', field: 'teacher_email' },
          { label: 'Student Email', field: 'student_email' },
          { label: 'Student Name', field: 'student_name' },
          { label: 'Student Level', field: 'student_level' },
          { label: 'Student Book Time', field: 'student_book_time', input_type: 'datetime' },
          { label: 'Canceled Time', field: 'canceled_at', input_type: 'datetime' },
          { label: 'Canceled By', field: 'canceled_by', input_type: 'select', options: CANCELED_BY_OPTIONS },
          { label: 'Cancelled before 6h', field: 'cancelled_before_6', value_handler: (model) => {return this.isCancelledMoreThanSixHoursAgo(model.canceled_at, model.date, model.time)}},
          { label: 'Teacher 1st Joining Time', field: 'teacher_join_at', input_type: 'datetime' },
          { label: 'Student 1st Joining Time', field: 'student_join_at', input_type: 'datetime' },
          { label: 'Actual Start Time', field: 'actual_start_at', input_type: 'datetime' },
          { label: 'Actual End Time', field: 'actual_end_at', input_type: 'datetime' },
          { label: 'Teacher Time (mins)', field: 'teacher_time' },
          { label: 'Class Duration (mins)', field: 'class_duration' },
          { label: 'Recording', field: 'recording', input_type: 'slot'},
          { label: 'Course', field: 'course' },
          { label: 'Lesson', field: 'lesson' },
        ]
      }
    },
    watch:{
      filter: {
        handler(){
          if (this.filter.student_email_key){
            this.filter.student_email_key = this.filter.student_email_key.trim();
            this.filter.student_email = {$regex: this.filter.student_email_key, "$options": 'i'}
          }
          else{
            delete this.filter.student_email
          }
          if (this.filter.student_name_key){
            this.filter.student_name_key = this.filter.student_name_key.trim();
            this.filter.student_name = {$regex: this.filter.student_name_key, "$options": 'i'}
          }
          else{
            delete this.filter.student_email
          }
          if (this.filter.teacher_email_key){
            this.filter.teacher_email_key = this.filter.teacher_email_key.trim();
            this.filter.teacher_email = {$regex: this.filter.teacher_email_key, "$options": 'i'}
          }
          else{
            delete this.filter.teacher_email
          }
          this.$refs.group_list.getList();
        },
        deep: true
      }
    },
    methods: {
      async getReport({ limit, page, query }) {
        let response_data = await service.getRoomInfoReport({
          filter: JSON.stringify(this.filter),
          limit,
          page
        });
        let list = [], total = 0;
        if (response_data) {
          list = response_data.list;
          total = response_data.total;
          this.total = total;
          let options;
          if (!STATUS_OPTIONS.length){
            options = buildSelectOptions(response_data.meta.STATUS_CMS)
            STATUS_OPTIONS.push(...options);
          }
          if (!CANCELED_BY_OPTIONS.length){
            options = buildSelectOptions(response_data.meta.CANCELED_BY);
            CANCELED_BY_OPTIONS.push(...options);
          }
          if (!TIME_PATTERN_OPTIONS.length){
            options = response_data.meta.TIME_PATTERN.map((item)=>{return {text: item, value: item}})
            options.unshift({text: "All", value: null})
            TIME_PATTERN_OPTIONS.push(...options);
          }
        }
        if (!courses_options.length){
          await this.getCourses();
        }
        return { list, total };
      },
      async getTeacherList(){
        let response_data = await service.getTeacherList();
        if (response_data){
          let options = response_data.list.map((item) => {return {text: item.email, value: item._id}});
          options.unshift({text: "All", value: null});
          teacher_email_options.push(...options);
        }
      },
      async getCourses(){
        let response_data = await service.getCourses();
        if (response_data){
          let options = response_data.list.map((item) => {return {text: item.title, value: item._id}});
          courses_options.push(...options);
        }
      },
      isCancelledMoreThanSixHoursAgo(canceledAt, date, time) {
        if(!canceledAt){
          return null
        }
        const cancelledDate = new Date(canceledAt);
        const [startTime] = time.split('-');
        const dateWithTime = new Date(`${date}T${startTime}`);
        const differenceInMilliseconds = cancelledDate - dateWithTime;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
        return differenceInHours <= -6 ? 'Yes' : 'No';
      }
    },
  }
  </script>