import api from '@/libs/axios'

export default {
  async getTeacherRatingStudentReport(params) {
    let response = await api.get('/admin/native11-ratings/teacher-rating-student', {
      params,
    })
    return response.data.data
  },
  async getStudentRatingTeacherReport(params) {
    let response = await api.get('/admin/native11-ratings/student-rating-teacher', {
      params,
    })
    return response.data.data
  },
  async getRoomInfoReport(params){
    let response = await api.get('/admin/native11-ratings/room-info-report', {
      params,
    })
    return response.data.data
  },
  async getTeacherList(params) {
    let response = await api.get('/admin/users/teachers', { params })
    return response.data.data
  },
  async getTeacherReport(params){
    let response = await api.get('/admin/native11-ratings/teacher-report', {
      params,
    })
    return response.data.data
  },
  async getCourses(params) {
    let response = await api.get('/admin/courses', {
      params,
    })
    return response.data.data
  },
}